/* Halcyon */

@font-face {
  font-family: 'Halcyon';
  src: local('Halcyon'), url(./Halcyon-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Halcyon';
  src: local('Halcyon'), url(./Halcyon-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Halcyon';
  src: local('Halcyon'), url(./Halcyon-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* ROBOTO */

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./RobotoCondensed-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./RobotoCondensed-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./RobotoCondensed-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

/* Source Sans */

@font-face {
  font-family: 'SourceSans';
  src: local('SourceSans'), url(./SourceSansPro-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SourceSans';
  src: local('SourceSans'), url(./SourceSansPro-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
