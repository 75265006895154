.ant-menu-item {
  font-weight: bold;
}
.ant-menu-item-selected {
  background-color: #f4f0f8 !important;
  color: #4913a3 !important;
}
.ant-menu-item::after {
  border-right: none !important;
}

.ant-menu-item:hover {
  color: #4913a3 !important;
}
