.custom-select {
  border: 2px solid whitesmoke;
  border-radius: 25px;
  padding: 5px;
}

.ant-select-selector {
  border-radius: 20px;
}
.ant-select-selection-search {
  border-radius: 20px;
}
.ant-form .custom-select.ant-select-selector {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.ant-input-password{
  border-radius: 20px;
}
.ant-input-affix-wrapper{
  border-radius: 20px;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 20px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 20px;
}

.ant-input {
  border-radius: 20px;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  border-radius: 20px;
}

.ant-input-number-input {
  border-radius: 20px;
}


